<template>
    <div class="w-100">
        <h1 class="pb-3"> {{ $t('navigation.general_configuration') }} </h1>
        <div v-if="loading"> {{ $t('form.please_wait') }} </div>

        <template v-else>
            <br />
            <div class="row py-2">
                <div class="col-2 fw-bold col-form-label-sm">B/L terms & conditions attachment</div>
                <div class="col-4 col-form-label-sm">PDF file will be attached to Bill of Ladings</div>
                <div class="col-4 pe-0">
                        <div v-if="item.file_info" class="mb-1">
                            <a class="col-5 col-form-label-sm" href="#">{{item.file_info[0].name}}</a>
                        </div>
                        <div v-else-if="item.file" class="mb-1">
                            <a class="col-5 col-form-label-sm" href="#" @click="download(item.file)">{{item.file.name}}.{{item.file.type}}</a>
                        </div>
                        <input type="file" @change="uploadFile($event, item, 'file_info')" />
                </div>
                
                <div class="row py-2">
                    <div class="col-2 fw-bold col-form-label-sm">Booking days</div>
                    <div class="col-4 col-form-label-sm">Number of working days a booking has to be made in advance of loading. Does not count weekend days.</div>
                    <div class="col-4 pe-0">
                        <FormItem :type="'text'" v-model="item.booking_days" />
                    </div>
                </div>
                    
                <div class="row py-2">
                    <div class="col-2 fw-bold col-form-label-sm">Delivery  days</div>
                    <div class="col-4 col-form-label-sm">Number of working days a booking unit can be delivered in advance of loading. Does not count weekend days. If set to 0 it will show text "please check with your local NMT representative</div>
                    <div class="col-4 pe-0">
                        <FormItem :type="'text'" v-model="item.delivery_days" />
                    </div>
                </div>
                    
                <div class="row py-2">
                    <div class="col-2 fw-bold col-form-label-sm">ICO mail footer</div>
                    <div class="col-4 col-form-label-sm">This line allows you the add a footer text to the email.</div>
                    <div class="col-4 pe-0">
                        <FormItem :type="'textarea'" v-model="item.ico_mail_footer" />
                    </div>
                </div>
            </div>

            <br />
            <div class="row">
                <div class="col-9"></div>
                <button id="savebutton" type="button" class="btn btn-success col-1" @click.prevent="save" :disabled="saving">{{$t('form.save')}}</button>
            </div>
        </template>
    </div>

</template>

<script>
    import generalConfigService from '@/services/GeneralConfigService';
    import fileService from "@/services/FileService";
    import store from '@/store/user'
    import moment from 'moment';

    export default {
        data() {
            return {
                item: [],
                loading: true,
                saving: false
            }
        },
        methods: {
            save() {
                this.saving = true;
                generalConfigService.update(this.item).then((response) => {
                    this.$toast.success(this.$t('masterdata.general_config_saved'));
                    this.item = response.data;
                    this.saving = false;
                }).catch(error => {
                    this.toastError(error);
                    this.saving = false;
                });
            },
            download(file){
                fileService.download(file.id).then(response => {
                    this.triggerDownload(response.data, file.name + '.' + file.type);
                }).catch(error => {
                    this.toastError(error)
                })
            }
        },
        mounted() {
            this.loading = true;
            generalConfigService.index().then(response => {
                this.item = response.data;
                this.loading = false;
            }).catch(error => {
                console.log('error', error)
            })
        }
    }
</script>