import {ApiService} from '@/services/ApiService'
import http from '@/http-common'

const URL = '/api/general-config'

class GeneralConfigService extends ApiService {
    constructor(){
        super()
    }
    getUrl(){
        return URL
    }
    index() {
        return http.get(`${URL}/index`)
      }
      update(data) {
        return http.post(`${URL}/update`, data)
      }
}

export default new GeneralConfigService()